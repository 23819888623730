import React from 'react'
import { Helmet } from 'react-helmet'

export default function RoyalHelmet({ title, description }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content='' />
      <meta property='og:type' content='image/png' />
      <meta property='og:url' content='https://apputils.royaladvice.com.br/images/RoyalAdviceIcone.png' />
    </Helmet>
  )
}