import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Modal, Icon, Input, Alert } from 'rsuite'

import { loginApiKey } from '../../realm/connect'
import RoyalHelmet from '../../components/RoyalHelmet'
import RefreshTokenSchema from '../../schemas/refresh_token'

import styles from './index.module.css'

export default function RefreshToken() {
  const [loading, setLoading] = useState(false)

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(RefreshTokenSchema),
    defaultValues: ''
  })

  const onSubmit = async ({ email }) => {
    Alert.closeAll()
    setLoading(true)

    try {
      const user = await loginApiKey()
      const result = await user.functions.resend_email_confirmation({ email })

      Alert.success(result.message, 0)

      setValue('email', '')
      setLoading(false)
    } catch ({ error }) {
      error
        ? Alert.error(JSON.parse(error).message, 0)
        : Alert.error('Erro inesperado, tente novamente.', 0)

      setLoading(false)
    }
  }

  return (
    <>
      <RoyalHelmet 
        title='Reenviar Código'
        description='Reenviar token de confirmação pelo email.'
      />

      <Modal
        size='xs'
        show={true}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 5
        }}
      >
        <Modal.Body>
          <p className={`${styles.modalTitle}`}>
            <Icon
              icon='key'
              style={{
                color: 'yellow',
                fontSize: 24
              }}
            />
            {'  '}
          Solicitar novo código
        </p><br />

          <p className={`${styles.modalMessage}`}>
            Insina o seu email abaixo para obter um novo código de acesso.
        </p><br />

          <form onSubmit={handleSubmit(onSubmit)}>
            <label style={{ fontSize: 16 }} htmlFor='email'>Email</label>
            <Input name='email' inputRef={register} />
            {errors.email && <div className={`${styles.errorInput}`}>{errors.email.message}</div>}

            <div className={`${styles.submitButton}`}>
              <Button loading={loading} appearance='primary' type='submit'>Confirmar</Button>
            </div>
          </form>

        </Modal.Body>
      </Modal>
    </>
  )
}