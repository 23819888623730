import React from 'react'
import { Modal, Icon } from 'rsuite'

import RoyalHelmet from '../../components/RoyalHelmet'
import logo from '../../LogoRoyal.svg'
import styles from './index.module.css'

export default function OpenApp() {
	return (
		<>
			<RoyalHelmet
				title='Aplicativo'
				description='Página para abrir o aplicativo através do site.'
			/>
			<div className={`${styles.container}`}>
				<div className={`${styles.imageContainer}`}>
					<img src={logo} className={`${styles.Applogo}`} alt="logo" />
				</div>
			</div>
		</>
	)
}
