import React from 'react'
import { Modal, Icon } from 'rsuite'

import RoyalHelmet from '../../components/RoyalHelmet'
import styles from './index.module.css'

export default function Start() {
  return (
    <>
      <RoyalHelmet 
        title='Sobre'
        description='Informações sobre a utilidade do site.'
      />

      <Modal
        size='xs'
        show={true}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 5
        }}
      >
        <Modal.Body>
          <p className={`${styles.modalTitle}`}>
            <Icon
              icon="exclamation-triangle"
              style={{
                color: 'yellow',
                fontSize: 24
              }}
            />
            {'  '}
          Informações sobre o site
        </p><br />

          <p className={`${styles.modalMessage}`}>
            Site exclusivivamente feito para renovar credências ou confirmar cadastro.
            Não existe nada neste link, verifique se seu link está correto e tente novamente.
        </p>
        </Modal.Body>
      </Modal>
    </>
  )
}
