import * as yup from 'yup'

const ResetPasswordSchema = yup.object().shape({
  password: yup.string()
    .required('Insira uma senha')
    .min(8, 'Coloque no mínino 8 caracteres')
    .test(
      'password',
      'Não pode inserir uma sequência de 3 carecteres iguais',
      (value) => {
        const pattern = /(.)\1\1+/g
        const regex = new RegExp(pattern, 'g')
        const newString = value.match(regex)

        return !newString
      }
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'As senhas devem corresponder')
})

export default ResetPasswordSchema