import * as Realm from 'realm-web';

const id = "royaladvicerealmapp-ttgwi";

const config = {
	id,
};

export async function loginApiKey() {
	const app = new Realm.App(config);
	// Create an API Key credential
	const credentials = Realm.Credentials.apiKey("aC2ui5iQzqaLfaYFOAAMtXubm9VDOsk0I6TtLvu8YkSIiPaZm39gVwOOFB6dE21d");
	try {
		if (!app.currentUser) {
			// Authenticate the user
			const user = await app.logIn(credentials)
			// `App.currentUser` updates to match the logged in user
			return user
		} else {
			return app.currentUser
		}
	} catch (err) {
		console.error("Failed to log in", err);
	}
}