import React from 'react';
import { Helmet } from 'react-helmet';

import Start from './routes/about';
import Confirm from './routes/confirm';
import RefreshToken from './routes/refresh_token';
import ResetPassword from './routes/reset_password';
import OpenApp from './routes/openapp';
import RoyalAdviceResetPassword from './routes/royaladvice_app_reset_password';
import RoyalAdviceConfirm from './routes/royaladvice_app_confirm_email';

/* import Downloads from './pages/downloads/index'
import Privacidade from './pages/privacidade/index'
import TermoDeUso from './pages/termodeuso/index' */
import {
	BrowserRouter as Router,
	Switch,
	Route,
	HashRouter,
	useLocation
} from "react-router-dom"

function App() {
	return (
		<Router>
			{/*<HashRouter>*/}
			<Switch>
				<Route exact path="/">
					<Start />
				</Route>
				<Route path="/hash/:hash">
					<Confirm />
				</Route>
				<Route path="/refreshtoken">
					<RefreshToken />
				</Route>
				<Route path="/resetpassword/:hash">
					<ResetPassword />
				</Route>
				<Route path="/royaladvice_app_reset_password/:hash">
					<RoyalAdviceResetPassword />
				</Route>
				<Route path="/royaladvice_app_confirm_email/:hash">
					<RoyalAdviceConfirm />
				</Route>
				<Route path="/openapp">
					<OpenApp />
				</Route>
				<Route path="*">
					<NoMatch />
				</Route>
				{/* <Route exact path="/">
					<Main />
				</Route>
				<Route path="/privacidade">
					<Privacidade />
				</Route>
				<Route path="/termodeuso">
					<TermoDeUso />
				</Route>
				 */}
			</Switch>
			{/*</HashRouter>*/}
		</Router>

	)
}

function NoMatch() {
	let location = useLocation();

	return (
		<>
			<Helmet>
				<title>Page Not Found</title>
			</Helmet>

			<div style={{
				display: 'flex', justifyContent: 'center', flexDirection: 'column',
				alignItems: 'center', minHeight: '100vh', maxHeight: '100vh',
				overflow: 'hidden', maxWidth: '100vw', minWidth: '100vw'
			}}>
				<h1>Error 404</h1>
				<h3>Página não encontrada <code>{location.pathname}</code></h3>
			</div>
		</>
	)
}

export default App