import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Modal, Icon, Input, InputGroup, Alert } from 'rsuite'
import { useParams } from "react-router-dom";

import { loginApiKey } from '../../realm/connect'
import RoyalHelmet from '../../components/RoyalHelmet'
import ResetPasswordSchema from '../../schemas/reset_password'

import styles from './index.module.css'

export default function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [fieldVisibility, setFieldVisibility] = useState({
    password: false,
    passwordConfirmation: false
  })
  let { hash } = useParams()

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: ''
  })

  const onSubmit = async ({ password }) => {
    setLoading(true)

    try {
      const user = await loginApiKey()
      const result = await user.functions.custom_verify_and_reset_password({ token: hash, new_password: password })

      Alert.success(result.message, 0)

      setValue('password', '')
      setValue('passwordConfirmation', '')
      setLoading(false)

    } catch ({ error }) {
      error
        ? Alert.error(JSON.parse(error).message, 0)
        : Alert.error('Erro inesperado, tente novamente.', 0)

      setLoading(false)
    }
  }

  const handleFieldVisibility = fieldName => setFieldVisibility(oldValue => ({ ...oldValue, [fieldName]: !fieldVisibility[fieldName] }))

  return (
    <>
      <RoyalHelmet 
        title='Nova Senha'
        description='Criar uma nova senha através do código de acesso.'
      />

      <Modal
        size='xs'
        show={true}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 5
        }}
      >
        <Modal.Body>
          <p className={`${styles.modalTitle}`}>
            <Icon
              icon="unlock-alt"
              style={{
                color: 'yellow',
                fontSize: 24
              }}
            />
            {'  '}
          Criar nova senha
        </p><br />

          <p className={`${styles.modalMessage}`}>
            Para criar uma nova senha, preencha os campos abaixo.
        </p><br />

          <form onSubmit={handleSubmit(onSubmit)}>
            <label style={{ fontSize: 16 }} htmlFor='password'>Senha</label>
            <InputGroup>
              <Input name='password' type={fieldVisibility.password ? 'text' : 'password'} inputRef={register} />
              <InputGroup.Button onClick={() => handleFieldVisibility('password')}>
                <Icon icon={fieldVisibility.password ? 'eye-slash' : 'eye'} size='lg' />
              </InputGroup.Button>
            </InputGroup>

            {errors.password && <div className={`${styles.errorInput}`}>{errors.password.message}</div>}

            <label style={{ display: 'flex', fontSize: 16, marginTop: 10 }} htmlFor='passwordConfirmation'>Confirmar senha</label>
            <InputGroup>
              <Input name='passwordConfirmation' type={fieldVisibility.passwordConfirmation ? 'text' : 'password'} inputRef={register} />
              <InputGroup.Button onClick={() => handleFieldVisibility('passwordConfirmation')}>
                <Icon icon={fieldVisibility.passwordConfirmation ? 'eye-slash' : 'eye'} size='lg' />
              </InputGroup.Button>
            </InputGroup>

            {errors.passwordConfirmation && <div className={`${styles.errorInput}`}>{errors.passwordConfirmation.message}</div>}

            <div className={`${styles.submitButton}`}>
              <Button loading={loading} appearance="primary" type="submit">Confirmar</Button>
            </div>
          </form>

        </Modal.Body>
      </Modal>
    </>
  )
}