/**
 * 
 * ESTRUTURA DO LINK:
 * https://apputils.royaladvice.com.br/royaladvice_app_confirm_email/${token}/${code}`
 */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Loader, Modal, Button, Icon } from 'rsuite';
import { useParams } from 'react-router-dom';

import { loginApiKey } from '../../realm/connect'
import RoyalHelmet from '../../components/RoyalHelmet'
import styles from './index.module.css'

export default function Confirm() {
  const [loading, setLoading] = useState(false)
  const [modalSuccess, setModalSuccess] = useState({ show: false, message: '' })
  const [modalError, setModalError] = useState({ show: false, message: '' })
  let { hash } = useParams()

  const errorMenssages = [
    'Token inválido ou expirado.',
    'Erro ao confirmar cadastro.',
    'Erro ao atualizar status.',
    'Usuário ja foi confirmado.'
  ]

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const user = await loginApiKey()
        const result = await user.functions.royaladvice_custom_verify_and_confirm_register(hash);

        setModalSuccess({
          show: true,
          message: result.message
        })

        setLoading(false);
      } catch ({ error }) {
        const message = JSON.parse(error).message

        setModalError({
          show: true,
          message: verifyMessage(message)
        })

        setLoading(false)
      }
    })()
  }, [])

  const verifyMessage = messageReturned =>
    errorMenssages.reduce((amt, messageError) =>
      messageError === messageReturned ? messageReturned : amt, 'Desculpa, ocorreu um erro inesperado.'
    )

  return (
    <>
      <RoyalHelmet
        title='Confirmar Email'
        description='Verificação de código do acesso para confirmar o email cadastrado na royal advice.'
      />

      <div className={`${styles.container}`}>
        {loading && <Loader size='md' content='Carregando' />}

        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 5
          }}
          className={`${styles.modal}`}
          show={modalSuccess.show}
        >
          <Modal.Body>
            <p className={`${styles.modalTitle}`}>
              <Icon
                icon='ok-circle'
                style={{
                  color: '#009900',
                  fontSize: 24
                }}
              />
              {'  '}
              {modalSuccess.message}
            </p><br />
          </Modal.Body>
        </Modal>

        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0
          }}
          show={modalError.show}
        >
          <Modal.Body>
            <p className={`${styles.modalTitle}`}>
              <Icon
                icon='warning'
                style={{
                  color: 'tomato',
                  fontSize: 24
                }}
              />
              {'  '}
              {modalError.message}
            </p>

            {/* {modalError.message === errorMenssages[0] && (
              <>
                <br />
                <p className={`${styles.modalMessage}`}>
                  Deseja solicitar outro token?
                </p>
              </>
            )} */}

            {(modalError.message === errorMenssages[1] || modalError.message === errorMenssages[2]) && (
              <>
                <br />
                <p className={`${styles.modalMessage}`}>
                  Pressione o botão para recarregar a página.
                </p>
              </>
            )}
          </Modal.Body>

          {/* {modalError.message === errorMenssages[0] && (
            <Modal.Footer>
              <Link to='/refresh'>
                <Button appearance='primary'>
                  Sim
                </Button>
              </Link>

              <Button appearance='subtle'>
                Não
              </Button>
            </Modal.Footer>
          )} */}

          {(modalError.message === errorMenssages[1] || modalError.message === errorMenssages[2]) && (
            <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button appearance='primary' onClick={() => window.location.reload(false)}>
                <Icon icon='refresh2' style={{ marginRight: 6 }} />
                Recarregar
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </>
  )
}